import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

const Wrapper = styled.div`
    width: 100vw;
    display: flex;
    position: relative;
    width: 100%;
    max-width: var(--width);
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    article{
        width: 100%;
        padding: 12px;
        section{
            h2 {
                width: 100%;
                position: relative;
                font-size: 1.2rem;
                margin-top: 50px;
                padding-bottom: 10px;
                margin-bottom: 20px;
                font-weight: 600;
                width: 100%;
                vertical-align: center;               
                &:before {
                    position: absolute;
                    content: "";
                    background: rgb(126,128,130);
                    background: -moz-linear-gradient(left,  rgba(126,128,130,1) 10%, rgba(224,224,224,1) 10%);
                    background: -webkit-linear-gradient(left,  rgba(126,128,130,1) 10%,rgba(224,224,224,1) 10%);
                    background: linear-gradient(to right,  rgba(126,128,130,1) 10%,rgba(224,224,224,1) 10%);
                    width: 100%;
                    height: 3px;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    opacity: 0.7;
                }
            }
            h3 {
                font-size: 1.1rem;
                font-weight: 600;
                margin-top: 35px;
                margin-bottom: 15px;
                display: flex;
                padding: 7px 0 7px 15px;
                position: relative;
                &:before {
                    content: "";
                    width: 3px;
                    height: 100%;
                    background: rgb(126,128,130);
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    border-radius: 5px;
                    opacity: 0.8;
                }
            }
            ul li{
                margin-bottom: 0.3rem
            }
            ol li{
                margin-bottom: 0.3rem
            }
        }
    }
`

const AboutPage = ({ data, pageContext, location }) => {
    return (
        <Layout location={location} title="About">
            <SEO
                title="About"
                description="about page"
            />
            <Wrapper>
                    <main style={{padding: `10px`}}>
                    <h2
                        style={{marginBottom: `0.1rem`}}    
                    >
                        About
                    </h2>
                        <span
                            style={{
                                display: `block`,
                                marginBottom: rhythm(1),
                                color: `#adadad`
                            }}
                        >
                        Last Updated: 2020-04-26
                        </span>
                    <h3
                        style={{marginBottom: `0.5rem`}}
                    >ya9do</h3>
                    <div>
                       ソフトウェアエンジニア。神奈川県、カリフォルニア州サンノゼに住んでました。 
                    </div>
                    </main>
            </Wrapper>
        </Layout>
    )
}

export default AboutPage
